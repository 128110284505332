import React from "react";
import design from "../assets/img-1.jpg";
import ServiceAlert from "./ServiceAlert";
import golden_gate_bridge from "../assets/golden_gate_bridge.webp";

const Banner = () => {
  return (
    <div className="bg-white">
      <div className="mb-16 flex flex-col md:gap-6 mx-auto xl:max-w-screen-2xl md:flex-row px-4 xl:px-36 pt-12">
        <div className="py-6 px-6 md:px-0">
          <a href="/bookings">
            <h5 className="mb-2 text-[28px] md:text-[28px] xl:text-[45px] text-center md:text-left font-bold tracking-tight">
              Get amazing photos at San Francisco attractions
            </h5>
          </a>
          <p className="mb-6 font-normal text-center md:text-left text-[16px] md:text-[16px] xl:text-[20px]">
            Create Instagram-worthy memories at the city's iconic landmarks
          </p>
          <div className="mb-8 flex flex-col md:flex-row justify-start items-center">
            <a
              href="https://book.stripe.com/28oeXGeid9TL7lu14k"
              target="_blank"
              rel="noreferrer"
              className="w-max bg-dark-primary text-white text-[21px] md:text-[21px] font-semibold items-center px-6 py-6 text-center rounded-md hover:bg-white hover:text-dark-primary hover:border-dark-primary transition-all duration-150 border-2 border-dark-primary"
            >
              Get professional photos
            </a>
            {/* <a
              href="#"
              className="bg-transparent  text-[16px] md:text-[18px] text-secondary font-medium items-center px-3 py-2 text-sm text-center rounded-md"
            >
              Adult tickets from <strong>US$63.00</strong>
            </a> */}
          </div>
          {/* <ServiceAlert isMobile={false} /> */}
        </div>
        <div className="hidden lg:block">
        <img
          className="-mb-8 rounded-xl sm:h-[] w-[82%] md:w-[400px] xl:w-[600px] object-cover mx-auto"
          src={golden_gate_bridge}
          alt="design"
        />
        </div>
      </div>
    </div>
  );
};

export default Banner;
