import React from "react";
import Faq from "./Faq";

const Faqs = () => {
  return (
    <div
      id="faqs"
      className="w-full flex flex-col items-center mx-auto xl:max-w-screen-lg px-4 xl:px-36 pb-12"
    >
      <h5 className="w-full mt-4 mb-2 text-[28px] md:text-[28px] xl:text-[45px] text-center font-bold tracking-tight">
        FAQ
      </h5>
      <div className="w-full">
        <Faq
          title="What locations in San Francisco do you offer for photoshoots?"
          description="Our most in-demand photoshoot locations in San Francisco include Fisherman's Wharf, the Golden Gate Bridge and the Ferry Building. However, we're happy to accommodate custom locations upon request."
        />
        <hr />
        {/* <Faq
          title="How far in advance should I book my photoshoot?"
          description="We recommend booking your photoshoot at least 2-4 weeks in advance to ensure availability, especially during peak tourist seasons. However, we understand that plans can change, so we'll do our best to accommodate last-minute bookings whenever possible."
        />{" "} */}
        <hr />
        <Faq
          title="What's included in your photography packages?"
          description="Our photography packages typically include a specified number of hours of shooting time, a professional photographer and 10 high-resolution edited photos depending on the package you choose. We also offer customization options to tailor the package to your needs."
        />{" "}
        <hr />
        <hr />
        {/* <Faq
          title="How soon will I receive my edited photos after the photoshoot?"
          description="We strive to deliver your edited photos promptly while ensuring the highest quality. Typically, you can expect to receive your edited photos within 1 week after the photoshoot. However, this timeline may vary depending on factors such as the volume of photos and any additional editing requests. We'll keep you informed throughout the process to ensure your satisfaction."
        /> */}
      </div>
    </div>
  );
};

export default Faqs;
